/**
 * Delays updates of a ref. It can be useful for delaying the visibility of loading indicators.
 *
 * @return A new delayed ref.
 * @param value the ref to delay
 * @param immediateValue if the given value has this value, the change will be immediate
 * @param ms time to wait in ms (default: 350)
 */
export const refDelayed = <T>(
  value: Ref<T>,
  immediateValue: T,
  ms: MaybeRef<number> = 300,
) => {
  const duration = computed<number>(() =>
    immediateValue === value.value ? 0 : unref(ms),
  )

  return refDebounced<T>(value, duration, { maxWait: duration })
}
